import React, { useState } from 'react';
import { Link } from "@reach/router";

import logo from './logo-marcus.svg';

const Header = () =>{
    const [mobNav, setMobNav] = useState("close");
    return(
      <header className="App-header">
      <div className="logo-wrapper">
        <div className="logoBg"></div>
        <img src={logo} className="logo" alt="Dr. Schlich" />
      </div>
        <nav>
        <button className={"mobNavOpen"} onClick={() => {mobNav === "close" ? setMobNav("open") : setMobNav("close")}}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul className={mobNav}>
          <li><Link to="/" onClick={() => {mobNav === "close" ? setMobNav("open") : setMobNav("close")}}>Startseite</Link></li>
          <li><Link to="/leistungen" onClick={() => {mobNav === "close" ? setMobNav("open") : setMobNav("close")}}>Unsere Leistungen</Link></li>
          <li><Link to="/impressum" onClick={() => {mobNav === "close" ? setMobNav("open") : setMobNav("close")}}>Impressum</Link></li>
        </ul>
      </nav>
    </header>
    )
}

export default Header