import React from 'react';

const Footer = () =>{

    return(
        <footer>
        <div className="box3 box">
            <h3>Video-Sprechstunde</h3>
            <div>
              Vereinbaren Sie Ihren Online-Termin wie gewohnt unter Tel. 06852 / 259
            </div>
          </div>
  
          <div className="box2 box">
            <h3>Sie erreichen uns unter:</h3>
            <div>Tel. 06852 / 259</div> 
            <div>Fax 06852 / 1790</div>
          </div>
  
          <div className="box1 box">
            <h3>Unser Sprechzeiten</h3>
            <div className="sprechGrid">
              <span id="tage1">Mo, Di, Do, Fr</span>
              <span id="zeiten1">07 - 12 Uhr</span>
              <span id="tage2">Di & Do</span>
              <span id="zeiten2">16 - 18:30 Uhr</span>
            </div>
          </div>
        </footer>
    )
}

export default Footer